import { BPEnum, CategoryType, LanguageEnum, PaginationType, ServiceType, ServiceTypeEnum } from '@/types';
import styles from '../landing.module.scss';
import csSVG from 'assets/images/landing2/svg2.svg';
import csEnSVG from 'assets/images/landing2/comming_soon_en.png';
import CategoryListComp from '../categoryList/categoryList.index';
import { SliderWrapperComp, AppLinkComp } from 'components';
import ServiceCardComp from '../serviceCard/serviceCard.index';
import { Button, Spin, message } from 'antd';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/context';
import { API, PATH } from '@/data';
import { RestAPI, useBP } from '@/scripts';
import Image from 'next/image';
import { __DatingFakeData } from '../landing.data';
import DatingCardComp from '../datingCard/datingCard.index';

const datingData = __DatingFakeData.sort(() => Math.random() - 0.5).slice(0, 30);

function LandingServiceContentComp({
    activeIndex,
    services,
    categories,
}: {
    services: PaginationType<ServiceType>;
    activeIndex?: ServiceTypeEnum;
    categories: CategoryType[];
}) {
    const bp = useBP();
    const { langText } = useContext(GlobalContext);
    const lang = langText.landing2;
    const [activeSlide, setActiveSlide] = useState(0);
    const isFA = langText.lang === LanguageEnum.fa;
    const [slug, setSlug] = useState<Partial<CategoryType>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [servicesList, setServicesList] = useState<PaginationType<ServiceType> | null>(services);

    useEffect(() => {
        setActiveSlide(0);
    }, [activeIndex]);

    useEffect(() => {
        if (slug === undefined) setServicesList(services);
        else {
            setServicesList(null);
            RestAPI.get<PaginationType<ServiceType>>(API.services.index, {
                lang: langText.lang,
                category_and_descendants: slug?.slug ?? '',
                order: 'most_priority',
            })
                .then((res) => setServicesList(res.data))
                .catch(() => messageApi.open({ type: 'error', content: lang.errorMessage }));
        }
    }, [slug]);

    const slider2Btn = (
        actionTitle: string,
        actionLoading: boolean,
        actionPath: string,
        sliderIndex: number,
        sliderLength: number,
    ) => {
        return (
            <div className={`${styles['arrow-btn']} center-content mt-4 gap-32`}>
                <span
                    onClick={() =>
                        setActiveSlide(
                            isFA
                                ? (prev) => (prev > 0 ? prev - 1 : 0)
                                : (prev) => (prev < sliderLength - 1 ? prev + 1 : sliderLength - 1),
                        )
                    }
                    className={`pointer center-content d-pre-md-none ${
                        (isFA && sliderIndex === 0) || !sliderLength
                            ? styles['disabled']
                            : langText.lang === LanguageEnum.en && sliderIndex === sliderLength - 1
                              ? styles['disabled']
                              : ''
                    }`}
                >
                    {isFA ? <TbArrowRight color="#ffffff" /> : <TbArrowLeft color="#ffffff" />}
                </span>
                <AppLinkComp href={actionPath}>
                    <Button loading={actionLoading} className="ph-5-i">
                        {actionTitle}
                    </Button>
                </AppLinkComp>
                <span
                    onClick={() =>
                        setActiveSlide(
                            isFA
                                ? (prev) => (prev < sliderLength - 1 ? prev + 1 : sliderLength - 1)
                                : (prev) => (prev > 0 ? prev - 1 : 0),
                        )
                    }
                    className={`pointer center-content d-pre-md-none ${
                        !sliderLength || (isFA && sliderIndex === sliderLength - 1)
                            ? styles['disabled']
                            : langText.lang === LanguageEnum.en && sliderIndex === 0
                              ? styles['disabled']
                              : ''
                    }`}
                >
                    {isFA ? <TbArrowLeft color="#ffffff" /> : <TbArrowRight color="#ffffff" />}
                </span>
            </div>
        );
    };

    return (
        <div className={`${styles['content-layout']}`}>
            {contextHolder}
            {activeIndex === ServiceTypeEnum.offer ? (
                <div className={`${styles['slider']}`}>
                    <CategoryListComp data={categories} onCategoryChange={(item) => setSlug(item)} slug={slug} />
                    {servicesList ? (
                        <SliderWrapperComp
                            items={servicesList.data}
                            child={ServiceCardComp}
                            noLoop
                            origin={0.03}
                            loadingHeight={315}
                            className={`pt-4 ${styles['slider-content']}`}
                            hideArrow={bp?.MD_LTE ? false : true}
                            activeSlide={activeSlide}
                            onSlideChange={(e) => setActiveSlide(e)}
                            layout={[
                                { max_width: BPEnum.SM, perView: 1.3, spacing: 12 },
                                { max_width: BPEnum.MD, perView: 2.3, spacing: 12 },
                                { max_width: BPEnum.LG, perView: 2.3 },
                                { max_width: BPEnum.XL, perView: 2.9 },
                                { max_width: BPEnum.XXL, perView: 3.9, origin: 0.04 },
                            ]}
                        />
                    ) : (
                        <div className={`${styles['loading-box']} center-content w-100`}>
                            <Spin />
                        </div>
                    )}
                    {slider2Btn(
                        servicesList && servicesList.count === 0
                            ? bp?.MD_LTE
                                ? lang.seeAllService
                                : lang.seeAllService
                            : bp?.MD_LTE
                              ? lang.seeAll
                              : lang.see + ' ' + (servicesList?.count ?? '') + ' ' + lang.buy2,
                        !servicesList,
                        servicesList && servicesList.count === 0
                            ? PATH(langText.lang).services.index()
                            : PATH(langText.lang).services.index({ category: slug?.slug ?? '' }),
                        activeSlide,
                        servicesList?.data.length ?? 0,
                    )}
                </div>
            ) : activeIndex === ServiceTypeEnum.dating ? (
                <div className={`${styles['slider']}`}>
                    <SliderWrapperComp
                        items={datingData}
                        child={DatingCardComp}
                        noLoop
                        origin={0.03}
                        loadingHeight={315}
                        className={`pt-4 ${styles['slider-content']}`}
                        hideArrow={bp?.MD_LTE ? false : true}
                        activeSlide={activeSlide}
                        onSlideChange={(e) => setActiveSlide(e)}
                        layout={[
                            { max_width: BPEnum.SM, perView: 1.3, spacing: 12 },
                            { max_width: BPEnum.MD, perView: 2.3, spacing: 12 },
                            { max_width: BPEnum.LG, perView: 2.3 },
                            { max_width: BPEnum.XL, perView: 2.9 },
                            { max_width: BPEnum.XXL, perView: 3.9, origin: 0.04 },
                        ]}
                    />
                    {slider2Btn(
                        lang.personalizeDating,
                        false,
                        PATH(langText.lang).panel.dating(),
                        activeSlide,
                        datingData.length,
                    )}
                </div>
            ) : (
                <div className={`${styles['coming-soon']} center-content`}>
                    <div className="ph-3">
                        <Image src={isFA ? csSVG : csEnSVG} alt="coming soon" width={570} height={370} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default LandingServiceContentComp;
