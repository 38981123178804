import styles from './FAQ.module.scss';
import '@/utilities/antd/collapse.scss';
import { IoArrowForwardCircleOutline, IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { Collapse, Button } from 'antd';
import { useContext, useState } from 'react';
import { FAQType, LanguageEnum } from 'types/index';
import { GlobalContext } from 'context';

function FAQComp(props: { className?: string; data: FAQType[] }) {
    const [activeKey, setActiveKey] = useState<string | string[]>([]);
    const langContext = useContext(GlobalContext).langText;
    const lang = langContext.landing;

    const list1: FAQType[] = [];
    const list2: FAQType[] = [];
    props.data
        .sort((a, b) => (b.order ?? 0) - (a.order ?? 0))
        .forEach((item, index) => {
            if (index % 2) list2.push(item);
            else list1.push(item);
        });

    return (
        <div className={`${styles['container']} main-content ${props.className ?? ''}`}>
            <div className={`${styles['header-faq']} d-flex justify-content-between align-items-center mb-3`}>
                <h2 className="t-h2 t-800">{lang.faq}</h2>
                <a href="https://wa.me/14169009821" target="_blank" className="d-pre-md-none">
                    <Button className="t-primary-i" icon={<IoChatbubbleEllipsesOutline size={18} />}>
                        {lang.support}
                    </Button>
                </a>
            </div>
            <p className="mb-6">{lang.faqDesc}</p>
            <div className={`${styles['collapse-layout']} d-flex mb-4`}>
                <Collapse
                    expandIcon={() =>
                        langContext.lang === LanguageEnum.en ? (
                            <IoArrowForwardCircleOutline size={21} />
                        ) : (
                            <IoArrowBackCircleOutline size={21} />
                        )
                    }
                    defaultActiveKey={activeKey}
                    onChange={setActiveKey}
                    expandIconPosition="end"
                    className={styles['faq-collapse']}
                    items={list1.map((question) => ({
                        key: question.id,
                        label: question.question,
                        children: <p> {question.answer} </p>,
                        className: `${styles['faq-item']} ${activeKey.includes(question.id.toString()) ? styles['active'] : ''}`,
                    }))}
                />
                <Collapse
                    expandIcon={() =>
                        langContext.lang === LanguageEnum.en ? (
                            <IoArrowForwardCircleOutline size={21} />
                        ) : (
                            <IoArrowBackCircleOutline size={21} />
                        )
                    }
                    defaultActiveKey={activeKey}
                    onChange={setActiveKey}
                    expandIconPosition="end"
                    className={styles['faq-collapse']}
                    items={list2.map((question) => ({
                        key: question.id,
                        label: question.question,
                        children: <p> {question.answer} </p>,
                        className: `${styles['faq-item']} ${activeKey.includes(question.id.toString()) ? styles['active'] : ''}`,
                    }))}
                />
            </div>
            <div className="center-content-y flex-column gap-8 d-none d-pre-md-flex mt-6">
                <p className="t-400 t-p1 mb-2">{lang.notFountQuestion}</p>
                <a href="https://wa.me/14169009821" target="_blank">
                    <Button className="center-content-x ph-5-i">{lang.support}</Button>
                </a>
            </div>
        </div>
    );
}

export default FAQComp;
