'use client';
import styles from './hint.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRealTimeState } from 'scripts';
import { Divider, Steps } from 'antd';
import { ServiceTypeEnum } from '@/types';
import { GlobalContext } from '@/context';

const duration = 5000;
const steps = 25;

function HintComp(props: { active: ServiceTypeEnum }) {
    const [index, setIndex] = useState(0);
    const progress = useRealTimeState(0);
    const { langText } = useContext(GlobalContext);
    const lang = langText.landing2;
    const timeout = useRef<NodeJS.Timeout>();

    const desc =
        props.active === ServiceTypeEnum.offer
            ? lang.offersHint.desc
            : props.active === ServiceTypeEnum.dating
              ? lang.datingHint.desc
              : props.active === ServiceTypeEnum.housing
                ? lang.housingHint
                : lang.eventsHint;
    const items =
        props.active === ServiceTypeEnum.offer
            ? lang.offersHint.steps
            : props.active === ServiceTypeEnum.dating
              ? lang.datingHint.steps
              : [];

    useEffect(() => {
        if (progress.get() >= 1) {
            progress.set(0);
            setIndex(index + 1 >= items.length ? 0 : index + 1);
        }
    }, [progress.dep]);

    useEffect(() => {
        clearInterval(timeout.current);
        if (props.active === ServiceTypeEnum.dating || props.active === ServiceTypeEnum.offer) {
            timeout.current = setInterval(() => {
                progress.set(progress.get() + 1 / steps);
            }, duration / steps);
        }
    }, [props.active]);

    useEffect(() => {
        progress.set(0);
        setIndex(0);
    }, [props.active]);

    return (
        <div className={`${styles.hint} ph-4 mt-4`}>
            <p>{desc}</p>
            {items.length ? (
                <>
                    <Steps
                        className="mt-2-i"
                        current={index}
                        percent={progress.dep * 100}
                        labelPlacement="vertical"
                        items={items.map((i) => ({ title: i.title }))}
                        onChange={(e) => {
                            setIndex(e);
                            progress.set(0);
                        }}
                    />
                    <h4 className="t-p1 mt-2-i">{items[index].desc}</h4>
                </>
            ) : null}
            <Divider className="mb-0-i mt-4-i d-pre-md-none-i" />
        </div>
    );
}

export default HintComp;
