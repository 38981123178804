import styles from './categoryList.module.scss';
import { CategoryType } from 'types';
import { useState } from 'react';
import { GlobalContext } from 'context';
import { useContext } from 'react';
import DragAbleComp from './dragAble/dragAble.index';

function CategoryListComp({
    data,
    onCategoryChange,
    slug,
}: {
    data: CategoryType[];
    onCategoryChange?: (e: Partial<CategoryType>) => void;
    slug?: Partial<CategoryType>;
}) {
    const lang = useContext(GlobalContext).langText.categoryList;
    const list: Partial<CategoryType>[] = [
        { title: lang.all_category, slug: '', priority: Math.max(...data.map((i) => i.priority ?? 0)) + 1 },
        ...data,
    ];
    const [activeIndex, setActiveIndex] = useState<Partial<CategoryType>>(list[0]);
    const innerData = data.filter((i) =>
        activeIndex.parent
            ? i.parent?.slug === activeIndex.parent.slug
            : activeIndex.slug
              ? i.parent?.slug === activeIndex.slug
              : false,
    );

    const categoryHandler = (item: Partial<CategoryType>) => {
        setActiveIndex(item);
        onCategoryChange && onCategoryChange(item);
    };

    return (
        <div className={`${styles['category-list']} flex-column`}>
            <DragAbleComp
                parentClassName="main-content ph-4"
                className={`${styles['parent-category']} d-flex align-items-center pe-3 ps-2`}
            >
                {list
                    .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
                    .filter((i) => !i.slug || !i.parent)
                    .map((item, index) => (
                        <div
                            onClick={() => categoryHandler(item)}
                            key={index}
                            className={`${styles['list-items']} pv-1 ${
                                slug?.parent
                                    ? slug.parent.slug === item.slug
                                        ? styles['active']
                                        : ''
                                    : slug?.slug
                                      ? item.slug === slug.slug
                                          ? styles['active']
                                          : ''
                                      : item.slug === ''
                                        ? styles['active']
                                        : ''
                            }`}
                        >
                            <p className="t-400 t-dark3">{item.title}</p>
                        </div>
                    ))}
            </DragAbleComp>
            <DragAbleComp
                parentClassName={`main-content ph-4 ${innerData.length ? 'mv-2' : ''}`}
                iconClassName="t-primary"
                className={`${styles['child-category']} d-flex align-items-center ph-3`}
            >
                {innerData
                    .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
                    .map((item, index) => (
                        <div
                            onClick={() => categoryHandler(item)}
                            key={index}
                            className={`${styles['list-items']} p-1 ${slug?.slug === item.slug ? styles['active'] : ''}`}
                        >
                            <p className="t-400">{item.title}</p>
                        </div>
                    ))}
            </DragAbleComp>
        </div>
    );
}

export default CategoryListComp;
